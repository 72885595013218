/* eslint-disable no-underscore-dangle */
import 'es6-promise/auto';
import 'isomorphic-fetch';
import 'core-js/stable';

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import isBrowserModern from '@honda-canada/sitecore-jss-common/lib/utils/isBrowserModern';
import i18nInit from '@honda-canada/sitecore-jss-common/lib/utils/i18nInit';
import AppRootWithUserLocation from '@honda-canada/sitecore-jss-common/lib/components/AppRootWithUserLocation';
import ErrorBoundary from '@honda-canada/sitecore-jss-common/lib/components/ErrorBoundary';
import { Provider as ReduxProvider } from 'react-redux';
import { Box } from '@honda-canada/design-system-react';
import { store } from '@honda-canada/sitecore-jss-components/lib/core/store';
import ReducersProvider from '@honda-canada/sitecore-jss-components/lib/components/ReducersProvider';
import LocalStorageProvider from '@honda-canada/sitecore-jss-components/lib/components/LocalStorageContext';
import BottomElementProvider from '@honda-canada/sitecore-jss-components/lib/components/Footer/service/BottomElementProvider';
import ModelFiltersContextProvider from '@honda-canada/sitecore-jss-components/lib/components/ModelFiltersContext';

import '@honda-canada/design-system-react/lib/motors-css.css';
import { MotorsTheme as Theme } from '@honda-canada/design-system-react/lib/motors-theme';

import componentFactory from './componentFactory';
import updateLanguageLink from './updateLanguageLinks';
import ApplicationErrorMessage from './ApplicationErrorMessage';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

let renderFunction = ReactDOM.render;

let initLanguage = 'en';

let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');

if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}

if (__JSS_STATE__) {
  renderFunction = ReactDOM.hydrate;
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

const { config } = __JSS_STATE__;

const App = () => {
  const [reloadLocationFromCookies, setReloadLocationFromCookies] = useState(false);

  return (
    <ErrorBoundary errorMessage={<ApplicationErrorMessage />}>
      <ReduxProvider store={store}>
        <HelmetProvider>
          <AppRootWithUserLocation
            path={window.location.pathname}
            Router={BrowserRouter}
            ReducersProvider={ReducersProvider}
            ssrState={__JSS_STATE__}
            updateExternalLanguageLinks={(language, routeLanguageSelectors) => {
              updateLanguageLink('#toggleUrl', language, routeLanguageSelectors);
              updateLanguageLink('.global-header-compressed-link.language', language, routeLanguageSelectors);
            }}
            config={config}
            errorMessage={<ApplicationErrorMessage is404 />}
            activePlaceholders={['main', 'footer', 'header']}
            modelFiltersContextProvider={ModelFiltersContextProvider}
            bottomElementProvider={BottomElementProvider}
            localStorageProvider={LocalStorageProvider}
            reloadLocationFromCookies={reloadLocationFromCookies}
            setReloadLocationFromCookies={setReloadLocationFromCookies}
            theme={Theme}
            mainContainer={({ children }) => (
              <Box bg="transparent" mx="auto">
                {children}
              </Box>
            )}
            componentFactory={componentFactory}
          />
        </HelmetProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
};

const renderApp = async () => {
  const dictionary = __JSS_STATE__?.viewBag?.dictionary;
  const rootElement = document.getElementById('jss-root');
  await i18nInit(initLanguage, dictionary, config);

  renderFunction(<App />, rootElement);
};

if (isBrowserModern()) {
  renderApp();
} else {
  import('./polyfills').then(renderApp);
}
